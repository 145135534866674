import {makeAutoObservable, runInAction} from "mobx";
import {callHttpWait} from "../network/fetch/Fetch";
import {ActionSate, DialogStatus, RestEnvironment, TypesOfUsers} from "../environment";
import {Dialog, Dialogs} from "./DataCord";
import {isnull} from "../utils";
import {ctx} from "./Context";


class ChatsPool {

    private $chats: Dialog[] = [];
    private $current: Dialog | undefined;
    private $watcherNews: Map<number, {count: number, last: number}> = new Map();


    public constructor() {
        makeAutoObservable(this);
    }

    public addNews(dialogid: number, senttime: number) {

        if (this.$current?.dialogid !== dialogid) {
            this.$watcherNews.set(dialogid, {count: 1 + isnull(this.$watcherNews.get(dialogid), 0), last: senttime})
        }
    }

    public clearNews(dialogid: number | undefined) {
        if (dialogid)
            this.$watcherNews.delete(dialogid);
    }

    public hasNews(dialogid: number): {count: number, last: number} | undefined {
        return this.$watcherNews.get(dialogid)
    }



    public loadChats(
        statusid: number,
        inform: (state: ActionSate, text?: string) => void) {
        if (isnull(ctx.usid, -1) > 0) {
            callHttpWait(
                RestEnvironment.DialogUrl, "GET", [],
                [
                    ["statusid", String(statusid)],
                    ["pagesize", "101"],
                    ["pagenumber", "0"],
                    ["userid", String(isnull(ctx.usid, -1))],
                    ["sortfield", "lastupdate"],
                    ["sortorder", "1"]
                ]
            )
                .then((data: Dialogs) => {
                    runInAction(() => {
                        this.$chats = [...data.content];
                        const currentDialogid = this.$current?.dialogid;
                        if (currentDialogid) {
                            const index = this.$chats.findIndex(val => val.dialogid === currentDialogid);
                            if (index < 0) {
                                this.clearNews(this.$current?.dialogid);
                                this.$current = undefined;


                            }
                            else {
                                this.$current = this.$chats[index];
                                this.clearNews(this.$current?.dialogid);
                            }
                        }

                    });
                    inform(ActionSate.COMPLETED);
                })
                .catch(error => {
                    inform(ActionSate.ERROR, error + "");
                })
        }
    }


    public closeChat(
        dialogid: number,
        inform: (state: ActionSate, text?: string) => void) {
        if (dialogid) {
            callHttpWait(
                RestEnvironment.DialogUrl, "PATCH", [], [],
                JSON.stringify(
                    {
                        dialogid: dialogid,
                        statusid: DialogStatus.CLOSE,
                        closedreasonid: 1
                    }
                )
            )
                .then((data: Dialog) => {
                    const index = this.$chats.findIndex(val => val.dialogid === dialogid);
                    const currentDialogid = this.$current?.dialogid;
                    runInAction(() => {
                        if (index >= 0)
                            this.$chats[index] = {...data};
                        if (currentDialogid === data.dialogid)
                            this.clearNews(this.$current?.dialogid);
                        this.$current = undefined;
                    });
                    inform(ActionSate.COMPLETED);
                })
                .catch(error => {
                    inform(ActionSate.ERROR, error + "");
                })
        }
    }

    public createNewChat(
        inform: (state: ActionSate, text?: string) => void) {
        if (isnull(ctx.usid, -1) > 0) {
            callHttpWait(
                RestEnvironment.DialogUrl, "POST", [['Content-Type', 'application/json']],
                [],
                JSON.stringify({
                    userid: Number(ctx.usid)
                })
            )
                .then((data: Dialog) => {
                    runInAction(() => {
                        this.$chats.push(data);
                        this.$current = this.$chats.find(val => val.dialogid === data.dialogid);
                        this.clearNews(this.$current?.dialogid);
                    });
                    inform(ActionSate.COMPLETED);
                })
                .catch(error => {
                    inform(ActionSate.ERROR, error + "");
                })
        }
    }

    public updateChat(dialog: Dialog) {
        const index = this.$chats.findIndex(val => val.dialogid === dialog.dialogid);
        if (index >= 0) {
            runInAction(() => {

                this.$chats[index] = {...dialog};
                if (this.$current?.dialogid === dialog.dialogid) {
                    this.$current = this.$chats[index];
                    this.clearNews(this.$current?.dialogid);
                }
            });
        }
    }

    public get Current(): Dialog | undefined {
        return this.$current;
    }

    public setCurrent(dialogid: number) {
        this.$current = this.$chats.find(val => val.dialogid === dialogid);
        this.clearNews(this.$current?.dialogid);
    }



    public Display(statusid: number): {dialog: Dialog, news: {count: number, last: number}|undefined}[] {
        return this.$chats.filter(v => statusid === v.statusid)
            .sort(
                (a: Dialog, b: Dialog) =>
                    b.lastupdate - a.lastupdate
            ).map<{dialog: Dialog, news: {count: number, last: number} | undefined}>((dialog => {
                const news = this.hasNews(dialog.dialogid);
                return {dialog: dialog, news: news}
            }
            ));

    }


    public get isOpenCurrent() {
        return ((this.$current?.statusid || DialogStatus.CLOSE) === DialogStatus.OPEN);
    }

    public get isRightAssinment() {
        if (this.$current) {
            if (this.$current.assignee) {
                if ([TypesOfUsers.Bots, TypesOfUsers.BotFather]
                    .indexOf(this.$current.assignee.usertypeid) >= 0) {
                    return false;
                } else
                    return true;
            }
            else {
                return true;
            }
        }
        else
            return false;

    }



    public get Editable() {
        return this.isOpenCurrent && this.isRightAssinment;
    }


    public get CurrentID() {
        if (this.$current !== undefined) {
            return this.$current.dialogid;
        }
        else {
            return -1;
        }
    }


}

const chatsPool = new ChatsPool();
export {chatsPool}




