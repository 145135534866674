import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import {observer} from 'mobx-react-lite';
import Webcam from "react-webcam";
import {base64ToFile} from '../../utils';
import {ctx} from '../../codes/Context';
import FlipCameraIosIcon from '@mui/icons-material/FlipCameraIos';
import {IconButton, Stack, Typography} from '@mui/material';
import FlashOnTwoToneIcon from '@mui/icons-material/FlashOnTwoTone';
import FlashOffTwoToneIcon from '@mui/icons-material/FlashOffTwoTone';
import NoFlashTwoToneIcon from '@mui/icons-material/NoFlashTwoTone';

const WebcamDialog = observer((props: {
    isOnCamera: () => boolean;
    restateCamera: (state: boolean) => void,
    captureFile: (file: File) => void
}) => {


    const [open, setOpen] = React.useState<boolean>(false);
    const [captured, setCaptured] = React.useState<boolean>(false);
    const [facingMode, setFacingMode] = React.useState('environment');

    const [torchEnabled, setTorchEnabled] = React.useState(false);
    const [noTorch, setnoTorch] = React.useState(false);

    const [loading, setLoading] = React.useState(true);
    const webcamRef = React.useRef(null);
    const [imgSrc, setImgSrc] = React.useState(null);


    const switchCamera = () => {
        setFacingMode((prevMode) => (prevMode === 'user' ? 'environment' : 'user'));
    };
    const handleUserMedia = () => {
        setLoading(false);
    };


    const toggleFlashlight = async () => {

        try {
            const constraints = {
                video: {facingMode: 'environment'},
            };
            const stream = await navigator.mediaDevices.getUserMedia(constraints);
            const track = stream.getVideoTracks()[0];

            if ('applyConstraints' in track) {
                await track.applyConstraints({
                    advanced: [{torch: !torchEnabled}],
                });
            }

            // Stop the stream after toggling the torch
            stream.getVideoTracks().forEach(track => track.stop());
            setTorchEnabled(prev => !prev); // Toggle the state
        } catch (error) {
            setnoTorch(true);
            console.error('Error accessing the flashlight:', error);
        }
    };

    const capture = React.useCallback(() => {
        const imageSrc = (webcamRef?.current as any)?.getScreenshot();
        setImgSrc(imageSrc);
    }, [webcamRef]);
    const clear = () => {
        setImgSrc(null);
    }

    const close = () => {
        setImgSrc(null);
        props.restateCamera(false);
        setLoading(true);
        if (torchEnabled)
            toggleFlashlight();
    }

    const setCapture = () => {
        if (imgSrc) {
            const date = new Date();
            const basefile = base64ToFile(imgSrc, (ctx.name + "_" + date.toLocaleDateString() + "_" + date.toLocaleTimeString()).replace(" ", "_") + ".jpg")
            close();
            props.captureFile(basefile);
        }
        else {
            close();
        }

    }

    return (
        <React.Fragment>
            <Dialog
                open={props.isOnCamera()}
                onClose={close}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title"
                    sx={{
                        display: "grid",
                        gridTemplateColumns: "1fr 100px "

                    }}>

                    <Typography>Фото</Typography>
                    <Stack direction="row">
                        <IconButton onClick={() => {if (!noTorch) toggleFlashlight()}}>
                            {noTorch
                                ? (<NoFlashTwoToneIcon color='warning' />)
                                : (torchEnabled
                                    ? (<FlashOffTwoToneIcon color='primary' />)
                                    : (<FlashOnTwoToneIcon color='primary' />)
                                )
                            }

                        </IconButton>
                        <IconButton onClick={switchCamera}>
                            <FlipCameraIosIcon color='primary' />
                        </IconButton>
                    </Stack>

                </DialogTitle>
                <DialogContent>
                    <div>
                        {loading && <div>Загрузка камеры...</div>}
                        <Webcam
                            hidden={imgSrc != null}
                            audio={false}
                            ref={webcamRef}
                            screenshotFormat="image/jpeg"
                            width={"100%"}
                            height={"auto"}
                            videoConstraints={{
                                facingMode: facingMode,
                            }}
                            onUserMedia={handleUserMedia}
                        />
                        {imgSrc && <img src={imgSrc} alt="Captured" />}

                    </div>
                </DialogContent>
                <DialogActions>
                    {imgSrc
                        ? (<Button onClick={clear}>Переснять</Button>)
                        : (<Button onClick={capture}>Снять</Button>)
                    }
                    <Button disabled={imgSrc === null} onClick={setCapture}>Использовать</Button>
                    <Button onClick={close}>Отменить</Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}
);
export default WebcamDialog;