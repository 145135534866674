import {ContextNames, ctx} from "../../codes/Context";


function prepareFetch(
    url: string,
    method: string,
    headers: [string, string][],
    queryparams?: [string, string][],
    body?: BodyInit
): {
    fetchurl: string,
    fetchparams: RequestInit,
} {

    headers.push([ContextNames.JETTON_HEAD, ctx.token]);

    const params: RequestInit = {
        method: method,
        headers: headers
    };

    if (body)
        params.body = body;

    let query: URLSearchParams = new URLSearchParams();
    if (queryparams) {
        for (let i = 0; i < queryparams.length; i++) {
            query.append(queryparams[i][0], queryparams[i][1]);
            //encodeURIComponent(queryparams[i][1]));
        }
        url = url + "?" + query.toString();
    }
    return {fetchurl: url, fetchparams: params};
}


function hasJson(response: Response): boolean {
    if (response.headers.has("Content-Type")) {
        if (response.headers.get("Content-Type")?.toLowerCase()?.includes("application/json")) {
            return true;
        } else {return false;}
    }
    else {return false;}
}

function hasByteStream(response: Response): boolean {
    if (response.headers.has("Content-Type")) {
        if (response.headers.get("Content-Type")?.toLowerCase()?.includes("application/octet-stream")) {
            return true;
        } else {return false;}
    }
    else {return false;}
}


function callHttp(
    url: string,
    method: string,
    headers: [string, string][],
    queryparams?: [string, string][],
    body?: BodyInit,
    envoy?: any,
    callback?: (error: boolean, data: any, envoy?: any) => void

) {
    const {fetchurl, fetchparams} = prepareFetch(url, method, headers, queryparams, body);
    fetch(fetchurl, fetchparams)
        .then(response => {
            if (response.ok) {
                if (hasJson(response)) {
                    return ((jsonInText: Promise<any>) => {
                        return new Promise((resolve, reject) => {
                            jsonInText
                                .then(result => {
                                    try {
                                        resolve(JSON.parse(result));
                                    }
                                    catch (gotErr) {
                                        reject(Error("Error " + gotErr));
                                    }                             
                                })
                                .catch(error => {
                                    reject(error);
                                });
                        })
                    })(response.text());
                } else if (hasByteStream(response)) {
                    return response.blob()
                }
                else {
                    return JSON.parse("{}");
                }
            }
            else {
                return ((errinTextData: Promise<any>) => {
                    return new Promise((resolve, reject) => {
                        errinTextData
                            .then(result => {
                                reject(response.status + ":" + response.statusText + ":" + result);
                            })
                            .catch(error => {
                                reject(response.status + ":" + response.statusText);
                            });
                    })
                })(response.text());
            }
        })
        .then(data => {
            if (callback) callback(false, data, envoy);
        })
        .catch(error => {
            if (callback) callback(true, (error.message||error) + " " + url, envoy);
        })
}

function callHttpWait(
    url: string,
    method: string,
    headers: [string, string][],
    queryparams?: [string, string][],
    body?: BodyInit

) {
    const {fetchurl, fetchparams} = prepareFetch(url, method, headers, queryparams, body);
    return (async () => {
        const response = await fetch(fetchurl, fetchparams);
        if (response.ok) {
            try {
                const data = await response.json();
                return data;
            } catch (error) {
                throw Error(error + "");
            }
        }
        else {
            try {
                const data = await response.text();
                throw Error(response.status + ":" + response.statusText + "data" + data);
            } catch (error) {
                throw Error(response.status + ":" + response.statusText);
            }

        }

    })()

}

export {callHttpWait, callHttp,prepareFetch};




