import {observer} from "mobx-react-lite";
import {StoredMessage} from "../../codes/DataCord";
import SendingState from "./SendingState";
import {Box, CircularProgress, Divider, IconButton, InputAdornment, Stack, Typography} from "@mui/material";
import {Download} from "@mui/icons-material";
import {useState} from "react";
import {pool} from "../../codes/Pool";
import {ctx} from "../../codes/Context";
import {ActionSate} from "../../environment";



const Attachments = observer((props: {message: StoredMessage}) => {
    const {message} = props;

    const [fileLoading, setfileLoading] = useState<Map<number, undefined>>(new Map());

    function download(docid: number, docds: string) {
        if (document.getElementById('blob_link')) {
            setfileLoading(map => {
                const newmap = new Map(map);
                newmap.set(docid, undefined);
                return newmap;
            });
            pool.getFileContent(docid)
                .then((data: any) => {
                    const blob_link = document.getElementById('blob_link') as any;
                    if (blob_link) {
                        const url = window.URL.createObjectURL(data);
                        blob_link.href = url;
                        blob_link.download = docds;
                        blob_link?.click();
                        window.URL.revokeObjectURL(url);
                        setfileLoading(map => {
                            const newmap = new Map(map);
                            newmap.delete(docid);
                            return newmap;
                        });
                    }

                })
                .catch((error: any) => {
                    ctx.AppState = {state: ActionSate.ERROR, text: error.message||"error to download"};
                })
        }

    }

    return (
        <>
            {message?.attachments && (
                <>
                    <Stack direction={"column"}>
                        <Divider orientation="horizontal" />
                        {message?.attachments?.map((atch: {docds: string, docid: number}) => {

                            return (
                                <Box display="flex" flexDirection="row" alignItems="center" >
                                    <Typography
                                        sx={{
                                            fontSize: 13,
                                            fontStyle: 'italic'

                                        }}
                                        key={atch.docid}

                                    >
                                        {atch.docds}</Typography>
                                    {
                                        (fileLoading.has(atch.docid))
                                            ? (<CircularProgress sx={{zoom: "50%"}} />)
                                            : (<IconButton size="small"
                                                onClick={() => {download(atch.docid, atch.docds)}}>
                                                <Download /></IconButton>)

                                    }
                                </Box>
                            )
                        })}
                    </Stack>
                </>
            )}
            <a id="blob_link" style={{display: 'none'}}></a>
        </>
    )
});

export {Attachments}