
import {useEffect, useRef, useState} from "react"
import {chatsPool} from "../codes/ChatsPool";
import {ContextNames, ctx, PAGES} from "../codes/Context";
import {observer} from "mobx-react-lite";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import ImageIcon from '@mui/icons-material/Image';
import WorkIcon from '@mui/icons-material/Work';
import BeachAccessIcon from '@mui/icons-material/BeachAccess';
import LockIcon from '@mui/icons-material/Lock';
import {dateReFormat, subsctruct, UTCTodate} from "../utils";
import LockOpenIcon from '@mui/icons-material/LockOpen';
import {Box, Button, ButtonGroup, Container, Divider, Fab, IconButton, Input, ListItemButton, ListItemIcon, Stack, Tabs, TextField, Tooltip, Typography} from "@mui/material";
import PostAddIcon from '@mui/icons-material/PostAdd';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import {ActionSate, DialogStatus, RestEnvironment} from "../environment";
import {ActionPanel} from "./panels/ActionPanel";
import {Dialog} from "../codes/DataCord";
import {callHttpWait} from "../network/fetch/Fetch";



const ChatsPage = observer(() => {

  const [chatStatus, setChatStatus] = useState(DialogStatus.OPEN);




  function createNewChat() {
    ctx.AppState = {state: ActionSate.LOADING, text: "Открытие нового диалога"};
    chatsPool.createNewChat(
      (state: ActionSate, text?: string) => {
        ctx.AppState = {state: state, text: text};
        if (state === ActionSate.COMPLETED)
          ctx.currentPage = PAGES.PAGE_MESSAGES;
      }
    );
  }

  function closeChat(dialogid: number) {
    ctx.AppState = {state: ActionSate.LOADING, text: "Закрытие чата"};
    chatsPool.closeChat(
      dialogid, (state: ActionSate, text?: string) => {
        ctx.AppState = {state: state, text: text};
        if (state === ActionSate.COMPLETED)
          setChatStatus(DialogStatus.CLOSE);
      }
    );
  }



  useEffect(() => {
    ctx.AppState = {state: ActionSate.LOADING};
    chatsPool.loadChats(chatStatus,
      (state: ActionSate, text?: string) => {
        ctx.AppState = {state: state, text: text};

      });
  },
    [chatStatus]);


  const wiredKey= "Для пользователя ID="+String(ctx.usid||0);

  const DataDisplay = chatsPool.Display(chatStatus);

  return (
    <>
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: "1fr"

        }}
      >

        <Stack direction={"row"} alignItems={"center"}
          sx={{ml: 0.5, pb: 0.3, mt: 0.3, pt: 0.3, pr: 0.7, pl: 0.5, boxShadow: 3, borderRadius: "5px", zIndex: 100}}
        >
          <ButtonGroup key="bh3" variant="text" sx={{mt: 0.1, ml: 1}}>
            <Fab key="bh4" color="primary" aria-label="add" size="small" sx={{opacity: "70%"}}
              onClick={() => createNewChat()}>
              <PostAddIcon key="bh5" />
            </Fab>
          </ButtonGroup>
          <ButtonGroup key="hbg" variant="text"
            sx={{mt: 0.1, ml: 1}}>
            <Button key="bhb" variant={chatStatus === DialogStatus.OPEN ? "contained" : "outlined"} color="primary"
              onClick={() => {setChatStatus(DialogStatus.OPEN)}}
            //sx={{borderRadius: 9}} 
            >Открытые </Button>
            <Divider orientation="vertical"  ></Divider>
            <Button key="bhb2" variant={chatStatus === DialogStatus.CLOSE ? "contained" : "outlined"} color="primary"
              onClick={() => setChatStatus(DialogStatus.CLOSE)}
            //sx={{borderRadius: 9}}
            >Закрытые </Button>


          </ButtonGroup>
          <ButtonGroup key="bh3" variant="text" sx={{mt: 0.1, ml: 2}}>
          <Fab key="bhg33" color="primary" aria-label="add" size="small" sx={{opacity: "70%"}}
            onClick={(event) => {
              event.preventDefault();
              ctx.makeAppDialog(true, true, "Изменить имя в чатах?",
                "", (res: boolean, extraValues?: {key: string, value: string}[]) => {
                  if (res) {
                    ctx.AppState = {state: ActionSate.LOADING};
                    callHttpWait(
                      RestEnvironment.SelfNameUrl, "POST",
                      [['Content-Type', 'application/json']],
                      [],
                      JSON.stringify(
                        {
                          userid: ctx.usid,
                          userds: extraValues?.find(v => v.key === wiredKey)?.value || ctx.name || ctx.usid
                        }
                      )
                    ).then((data: any) => {
                      ctx.AppState = {state: ActionSate.COMPLETED};
                      if (data.userds) {
                        ctx.externalName = data.userds;
                      }
                    })
                      .catch((error: any) => {
                        ctx.AppState = {state: ActionSate.ERROR, text: "Update selfname " + error};
                      })

                  }
                }, [{key: wiredKey, value: ctx.name || ""}]);
              ctx.showAppDialog();
            }}>
            <ManageAccountsIcon key="bh6" />
          </Fab>
          </ButtonGroup>
        </Stack>





      </Box>


      <Box
        key="box"
        sx={{
          display: 'flex',
          flex: 1,
          minHeight: 0,
          px: 2,
          py: 3,
          overflowY: 'auto',
          flexDirection: 'column',
          //height: 'calc(100dvh-76px)'
          mb: 1
        }}
      >

        <List key="list" sx={{bgcolor: 'background.paper'}} >
          {DataDisplay.map((chat: {dialog: Dialog, news: {count: number, last: number} | undefined}) => {
            return (
              <>
                <ListItem key={chat.dialog.dialogid + "l"} >
                  <ListItemIcon >
                    {(chatStatus === DialogStatus.OPEN)
                      ? (
                        <Stack direction="column">
                          <IconButton onClick={(event) => {
                            event.preventDefault();
                            ctx.makeAppDialog(true, true, "Закрытие",
                              "Закрыть диалог?", (res: boolean) => {
                                if (res)
                                  closeChat(chat.dialog.dialogid);
                              });
                            ctx.showAppDialog();
                          }}>
                            <LockOpenIcon color="primary" />
                          </IconButton>
                          <Typography fontSize={13}>
                            {
                              UTCTodate((chat.news) ? (chat.news.last) : chat.dialog.lastupdate)
                            }
                          </Typography>
                        </Stack>)
                      : (<Stack key={chat.dialog.dialogid + "sa"} direction="column">
                        <LockIcon color="disabled" />
                        <Typography fontSize={13}>
                          {
                            UTCTodate((chat.news) ? (chat.news.last) : chat.dialog.lastupdate)
                          }
                        </Typography>
                      </Stack>)
                    }
                  </ListItemIcon>
                  <ListItemButton key={chat.dialog.dialogid + "lb"} selected={(chatsPool.CurrentID === chat.dialog.dialogid)}
                    onClick={() => {
                      chatsPool.setCurrent(chat.dialog.dialogid);
                      ctx.currentPage = PAGES.PAGE_MESSAGES;
                    }}>

                    <ListItemText key={chat.dialog.dialogid + "lt"}
                      sx={{...((chat.news) && (chat.news.count > 0)) ? {color: "#c40c0c"} : {}}}


                      primary={chat.dialog.team.teamds + " " + ((chat.news) && (chat.news.count > 0) ? ("(" + String(chat.news.count) + ")") : "")}
                      secondary=
                      {chat.dialog.assignee?.userds}
                    ></ListItemText>
                    <Tooltip title={JSON.stringify(chat)}>
                      <KeyboardDoubleArrowRightIcon />
                    </Tooltip>
                  </ListItemButton>
                </ListItem>
                <Divider variant="middle" component="li" />
              </>
            );
          })}
        </List>
      </Box>

    </>
  );
}

);
export {ChatsPage}