import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {observer} from 'mobx-react-lite';
import {ctx} from '../../codes/Context';
import {Stack, TextField, Typography} from '@mui/material';


const AppDialog = observer(() => {

    const [extraValues, setExtraValues] = React.useState<{key: string, value: string}[]>([]);

    function getStockValue(key: string) {
        const elem = extraValues.find(v => v.key === key);
        return elem?.value || "";
    }

    function setStockValue(key: string, value: string) {
        setExtraValues(old => {
            const elem = old.find(v => v.key === key);
            if (elem) {
                elem.value = value;
            }
            return [...old];
        })
    }

    const config = ctx.AppDialog;
    const handleClose = (result: boolean) => {
        ctx.hideAppDialog();
        if (config?.callback)
            if (extraValues.length > 0) {
                config?.callback(result, [...extraValues]);
            } else
                config?.callback(result);
    };

     React.useEffect(() => {
    if (config?.useTextExtraEdit) {
        setExtraValues([...config?.useTextExtraEdit]);
    }},
    [config?.useTextExtraEdit]);


    return (
        <React.Fragment>
            <Dialog
                open={config?.show || false}
                onClose={() => handleClose(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {config?.capt}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {config?.text}
                        {extraValues.map(ev => {
                            return (<Stack direction={"column"} gap={1} >
                                <Typography>{ev.key}</Typography>
                                <TextField value={getStockValue(ev.key)} onChange={(e) => {
                                    setStockValue(ev.key, e.target.value);
                                }} />
                            </Stack>)
                        })}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    {(config?.btnOk) &&
                        (<Button onClick={() => handleClose(true)}>Применить</Button>)
                    }
                    {(config?.btnCanel) &&
                        (<Button onClick={() => handleClose(false)}>Закрыть</Button>)
                    }
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}
);
export default AppDialog;