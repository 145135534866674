import {makeAutoObservable, runInAction} from "mobx";
import {ctx} from "./Context";
import {callHttpWait, prepareFetch} from "../network/fetch/Fetch";
import {ActionSate, CandidateState, FetchDirection, RestEnvironment, TypeOfMessages, TypesOfUsers} from "../environment";
import {sendToSocket, WrapperSocketMessage} from "../network/socket/Sock";
import {Message, Messages, StoredMessage} from "./DataCord";
import {DateToEpoch, isnull} from "../utils";


const $t = "t$", $m = "m$";

enum Direct {
    IN,
    OUT
}
enum Who {
    AGENT,
    BOT
}

type Attributes =
    {direct: Direct, who: Who}

class Pool {

    private $messages: Map<string, StoredMessage> = new Map();
    private $highlight: string | undefined = undefined;

    public get highlight() {
        return this.$highlight;
    }
    public set highlight(value) {
        runInAction(() => {
            this.$highlight = value;
        });
    }

    public get highlightText() {
        return this.$messages.get(this.$highlight || "")?.content
    }

    public get highlightID() {
        return this.$messages.get(this.$highlight || "")?.messageid
    }

    public get highlightIsMe() {
        return this.$messages.get(this.$highlight || "")?.sender?.userid === ctx.usid
    }





    public constructor() {
        makeAutoObservable(this);
    }


    public storeMessage(value: Message, candidate: number): string {
        let uuid: string = "";
        if (candidate === CandidateState.NO) {

            uuid = $m + value.messageid;
            // delete temp message 
            // eslint-disable-next-line eqeqeq
            if (value.sender.userid == Number(ctx.usid)) {
                if ((value.trackingid) && (value.trackingid.length > 0)) {
                    if (this.$messages.has($t + value.trackingid)) {
                        this.$messages.delete($t + value.trackingid);
                    }
                }
            }
        }
        else {
            uuid = $t + value.trackingid;
        }
        this.$messages.set(uuid, {...value, candidate: candidate, uuid: uuid})
        return uuid;
    }

    public loadMessages(
        dialogid: number,
        messageid: number | undefined,
        fetchDirection: string,
        keep: boolean,
        inform?: (state: ActionSate, text?: string, count?: number) => void) {

        if (isnull(dialogid, -1) > 0) {

            runInAction(() => {
                if (!keep)
                    this.clearMessages();
            });

            callHttpWait(
                RestEnvironment.MessagesUrl,
                "GET", [], [
                ["dialogid", String(dialogid)],
                ["poolsize", String(RestEnvironment.poolSize)],
                ["indexdir", fetchDirection],
                ["indexid", String(isnull(messageid, -1))]
            ]
            ).then((data: Messages) => {
                data.content.forEach((element: Message) => {
                    runInAction(() => {
                        this.storeMessage(element, CandidateState.NO);

                    });
                });

                if (inform)
                    inform(ActionSate.COMPLETED, "", data.content.length);
            })
                .catch(error => {
                    if (inform)
                        inform(ActionSate.ERROR, error + "");

                })
        }
    }

    public loadMessagesBack(dialogid: number, inform?: (state: ActionSate, text?: string, count?: number) => void) {
        if (isnull(dialogid, -1) > 0) {

            let find_messageid: number | undefined, senttime: number = 0;
            this.$messages.forEach((value, key) => {
                if (value.dialogid === dialogid) {
                    // if (senttime === 0) {
                    if (find_messageid === undefined) {
                        find_messageid = value.messageid;
                        senttime = value.senttime;
                    }
                    else {
                        // if (value.senttime < senttime) {
                        if (value.messageid < (find_messageid || value.messageid + 1)) {
                            find_messageid = value.messageid;
                            senttime = value.senttime;
                        }
                    }
                }
            });

            if (find_messageid) {
                this.loadMessages(dialogid, find_messageid, FetchDirection.BACK, true, inform);
            }
        }
    }

    public propogateCandidat(uuid: string, candidate: number) {

        if (this.$messages.has(uuid)) {
            this.$messages.set(uuid,
                {...this.$messages.get(uuid), candidate: candidate} as StoredMessage);
        }
    }


    public clearMessages() {
        this.$messages.clear();
    }


    private createMessage(
        dialogid: number,
        messagetypeid: number,
        content: string,
        replymessageid?: number,
        attachments?:
            {
                docds: string,
                docid: number
            }[],
        buttons?:
            {
                botstepid: number,
                botstepds: string
            }[],
        replycontent?: string
    ): Message {
        return {
            messageid: -1,
            dialogid: dialogid,
            sender: {
                userid: Number(ctx.usid),
                userds: String(ctx.usid),
                usertypeid: TypesOfUsers.Users
            },
            messagetypeid: messagetypeid,
            content: content,
            ...(replymessageid) ? {replymessageid: replymessageid} : {},
            senttime: DateToEpoch(new Date()),
            trackingid: ctx.usid + "$" + Date.now() + "$" + Math.round((Math.random() * 1000)),
            ...((attachments) ? {
                attachments: [
                    ...attachments.map((elem: {docds: string, docid: number}) => {
                        return {docds: elem.docds, docid: elem.docid}
                    })
                ]
            } : {}),
            ...((buttons) ? {
                buttons: [
                    ...buttons.map((elem: {botstepid: number, botstepds: string}) => {
                        return {botstepid: elem.botstepid, botstepds: elem.botstepds}
                    })
                ]
            } : {}),
            ...(replycontent) ? {replycontent: replycontent} : {}


        } as Message

    }


    public is(msg: Message): Attributes {
        // eslint-disable-next-line eqeqeq
        if (msg.sender.userid == Number(ctx.usid)) {
            if (msg.messagetypeid === TypeOfMessages.OPTSELECT) {
                return {direct: Direct.OUT, who: Who.BOT}
            }
            else {
                return {direct: Direct.OUT, who: Who.AGENT}
            }
        } else {
            if (
                (msg.messagetypeid === TypeOfMessages.OPTBUTTONS) ||
                (msg.messagetypeid === TypeOfMessages.OPTDONE)
            ) {
                return {direct: Direct.IN, who: Who.BOT};
            }
            else {
                return {direct: Direct.IN, who: Who.AGENT};
            }

        }
    }


    public sendMessage(
        dialogid: number,
        messagetypeid: number,
        content: string,
        replymessageid?: number | undefined,
        botstepid?: number | undefined,
        botstepds?: string | undefined,
        docid?: number | undefined,
        docds?: string | undefined,
        replycontent?: string | undefined,

    ) {

        if ((isnull(dialogid, -1) > 0) &&
            (isnull(ctx.usid, -1) > 0)
        ) {

            let attachments = undefined;
            let buttons = undefined;

            if (docid) {
                attachments = [
                    {
                        docds: docds || "FileName",
                        docid: docid
                    }];
            }
            if (botstepid) {
                buttons = [
                    {
                        botstepid: botstepid,
                        botstepds: botstepds || "BotStep"
                    }];
            }

            const newMessage: Message = this.createMessage(
                dialogid,
                messagetypeid,
                content,
                replymessageid,
                attachments,
                buttons,
                replycontent
            );

            const uuid = this.storeMessage(newMessage, CandidateState.SENDING);
            sendToSocket({packet: {datatype: "message", data: newMessage}, uuid: uuid} as WrapperSocketMessage);
        }
    }


    public Display(dialogid: number): StoredMessage[] {

        const filteredMessages = Array.from(this.$messages.values())
            .filter((v: StoredMessage) => v.dialogid === dialogid);

        const sortedMessages = filteredMessages.sort(
            (a: StoredMessage, b: StoredMessage) =>
                b.senttime - a.senttime ||
                b.messageid - a.messageid
        );

        // sortedMessages.forEach(v=>
        //     console.log(v.messageid,v.senttime,v.content)
        // )

        return sortedMessages;

    }


    public getFileContent(
        docid: number
    ) {
        const {fetchurl, fetchparams} = prepareFetch(
            RestEnvironment.DocDowloadUrl, "GET", [], [["docid",String(docid)]],undefined);
        return (async () => {
            const response = await fetch(fetchurl, fetchparams);
            if (response.ok) {
                try {
                    const data = await response.blob();
                    return data;
                } catch (error) {
                    throw Error(error + "");
                }
            }
            else {
                let unpackError: string | undefined
                try {
                    unpackError = await response.text();
                } catch (error) {
                    throw Error(response.status + "-" + response.statusText);
                }
                throw Error(response.status + "-" + response.statusText + ":" + unpackError);
            }

        })()
    }


    public uploadFile(
        files: File[],
        parts?: [string, string][]) {

        const formData = new FormData();
        files.forEach((file: File) => {
                formData.append("file", file)
        });
        parts?.forEach(e => {
            formData.append(e[0], e[1]);
        })

        const {fetchurl, fetchparams} = prepareFetch(
            RestEnvironment.DocUploadUrl, "POST", [], [],formData);
        
            return (async () => {
                const response = await fetch(fetchurl, fetchparams);
                if (response.ok) {
                    try {
                        const data = await response.json();
                        return data;
                    } catch (error) {
                        throw Error(error + "");
                    }
                }
                else {
                    let unpackError: string | undefined
                    try {
                        unpackError = await response.text();
                    } catch (error) {
                        throw Error(response.status + "-" + response.statusText);
                    }
                    throw Error(response.status + "-" + response.statusText + ":" + unpackError);
                }
    
            })()
       
    }
}

const pool = new Pool();


export {pool, Direct, Who}
export type {Attributes}